import { Select } from "../select/select";
import { Product } from "../products/product-types";
import { MixingTableControlsProps } from "../../containers/mixing-table/mixing-table-types";
import { StyledVerticalSpace } from "../layout/layout.styles";

export const MixingTableControls = (props: MixingTableControlsProps) => {
    const onProduct1Change = (val: string) => {
        props.updateCurrentProduct(val);
        props.updateProduct2('');
    };

    const onProduct2Change = (val: string) => {
        props.updateStatusFilter([]);
        props.updateProduct2(val);
    };

    const onStatusFilterUpdate = (val: string[]) => {
        props.updateProduct2('');
        props.updateStatusFilter(val);
    };

    const productSelectOptions = () => {
        return props.products.map((product: Product) => ({
            value: product.slug,
            text: product.name,
        }));
    };

    const productSelect2Options = () => {
        return [{ value: '', text: 'Samtliga preparat' }, ...productSelectOptions().filter((option) => option.value !== props.currentProduct)];
    };

    const statusSelectOptions = [
        { value: 'B', text: 'Får blandas' },
        { value: 'BA', text: 'OK med viss antagonism' },
        { value: 'BR', text: 'OK men resistensrisk' },
        { value: 'BO', text: 'OK under omrörning' },
        { value: '-', text: 'Ej blandbar' },
        { value: 'B*', text: 'Kan blandas men passar ej' },
        { value: 'U', text: 'Blandbart utan vätmedel' },
        { value: 'IG', text: 'Ingen uppgift eller blandningen ej aktuell' },
    ];

    return (
        <>
            <Select
                mode="basic"
                label="Välj preparat 1"
                value={props.currentProduct ? props.currentProduct : ''}
                options={productSelectOptions()}
                onChange={onProduct1Change}
                searchable={true}
            />
            <StyledVerticalSpace />
            <Select
                mode="basic"
                label="Välj preparat 2 (frivilligt)"
                value={props.product2 ? props.product2 : ''}
                options={productSelect2Options()}
                onChange={onProduct2Change}
                searchable={true}
                disabled={!props.currentProduct || !props.currentProduct1hasMixings}
            />
            <StyledVerticalSpace />
            <Select
                mode="multiple"
                label="Filtrera på kompatibilitetsgrupp"
                value={props.statusFilter ? props.statusFilter : []}
                options={statusSelectOptions}
                onChange={onStatusFilterUpdate}
                disabled={!props.currentProduct || !props.currentProduct1hasMixings}
                searchable={true}
            />
        </>
    );
};
